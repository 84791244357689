import React from 'react';
import PageHeader from '../components/content/PageHeader';
import Layout from '../modules/Layout';
import Text from '../components/content/Text';
import ContentIconTeaser from '../components/content/IconTeaser';
import ContentTeaser from '../components/content/ContentTeaser';
import { beruf, haftpflicht, hausratSliderItem, KpiItems, risiko, sliderItems } from '.';
import ContentSlider from '../components/content/ContentSlider';
import Kpi from '../components/content/Kpi';
import FamilyCheck from '../components/content/FamilyCheck';

const iconTeserItems = [
  {
    text: 'Weltweiter Schutz, rund um die Uhr – in Be­ruf und Frei­zeit',
  },
  {
    text: 'Das Extra: Ihr persön­licher Lei­stungs­lotse bzw. Lei­stungs­lotsePlus',
  },
  {
    text: 'Kapital­zahl­ung, wenn nach einem Un­fall körper­liche Beeinträ­chtig­ungen zurück­bleiben',
  },
];

const contentTeaserItems = [beruf, risiko, haftpflicht];

const Unfall = () => (
  <Layout
    pageName="IIIᐅ Familienversicherung  | ÖVB "
    description="Versicherungscheck der ÖVB online ✅ Versicherungscheck der ÖVB online ✅ Welche Versicherungen brauchen Familien? ✅ Wie schützen Sie Ihre Familie am besten? ᐅ Jetzt informieren! "
  >
    <PageHeader
      desktopImage="headerUnfall"
      mobileImage="headerUnfall_mob"
      badge1Line1="Kinder die Welt"
      badge1Line2="entdecken,"
      badge2Line1="geben wir Rückhalt."
    />
    <Text
      textAboveHeadline="Unfallschutz der ÖVB"
      headline="Weil ein kleiner Sturz große Folgen haben kann"
      text="<p>Auf Bäume klettern, Radfahren … Kids lieben es, die Welt zu entdecken. Aber was, wenn dabei etwas schief geht? Klar beschützen Sie Ihre Kinder 24/7. Aber Sie sind nun mal keine Superhelden. Sie sind super, so wie Sie sind und machen, was Sie können. Zum Beispiel: Ihre Familie ausreichend für den Fall eines Unfalls absichern.</p>"
    />
    <FamilyCheck
      headline="Wie viel Sicherheit braucht Ihre Familie?"
      text="Klar wissen Sie, dass Sicherheit absolut wichtig ist und Versicherungen
      sein müssen. Aber welche? Und vor allem, welche passen wirklich zu Ihnen
      und Ihrer Lebenssituation? Klicken Sie sich einfach kurz durch unseren Familien-Check. Wir zeigen Ihnen dann im
      Anschluss direkt die Versicherungen, die Sie auf jeden Fall haben sollten."
    />
    <ContentIconTeaser
      items={iconTeserItems}
      btnTarget="https://www.oevb.de/content/privat/versicherungen/einkommensschutz-und-unfall/unfall/"
      btnText="Mehr erfahren"
    />
    <ContentTeaser
      headline="Unsere stärksten Versicherungen für Sie und die Kids"
      text="Neben unserem ÖVB Unfallschutz sind auch diese drei Versicherungen wichtig, um gut abgesichert in Ihre Familienzukunft zu starten. Stiftung Warentest empfiehlt unsere stärksten Versicherungen für junge Familien ebenfalls."
      items={contentTeaserItems}
    />
    <Text
      headline="Wenn Sie für Ihre Familie auf Nummer sicher gehen wollen"
      text="Noch mehr Versicherungen? Reichen nicht zwei oder drei? Das entscheiden natürlich Sie und hängt zudem von Ihrer Lebenssituation ab. Aber schauen Sie einfach mal durch. Wir haben für alle Ansprüche die passende Versicherung – und passen uns auch Ihren individuellen Wünschen an."
    />
    <ContentSlider items={[hausratSliderItem, ...sliderItems]} />
    <Kpi
      headline="Warum ÖVB?"
      kpiItems={KpiItems}
      btnTarget="https://www.oevb.de/content/privat/service/betreuersuche/"
      btnText="Berater finden"
    />
  </Layout>
);

export default Unfall;
